import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthService, CourseService } from '../../services';

import './Catalog.scss';
import { Header, Footer, Progress, withAuth } from '../../components';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IUnit, ILesson, ICourse } from '../../interfaces';
import Loader from './Loader';
import ProgressiveImage from 'react-progressive-image-loading';
import blurImage from '../../assets/images/blur.jpg';

export interface Props {
  children?: React.ReactNode;
  classes: any;
  timer: any;
  lessons: any;
  match: any;
  showLoader: any;
  hideLoader: any;
  navigate: any;
}

const styles: any = (theme: any) => {
  return ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    main: {
      marginTop: theme.spacing.unit * 15,
    },
    branding: {
      justifyContent: 'center',
    },
    brandingIcon: {
      fontSize: '100px',
      color: 'red',
    },
    nested: {
      paddingLeft: theme.spacing.unit * 4,
    },
  });
};

class Catalog extends React.Component<Props, any> {
  public auth = new AuthService();
  private CourseService = new CourseService();
  constructor(props: Props) {
    super(props);
    this.state = {
      loginError: false,
      showProgress: false,
      courses: [],
    };
  }

  public componentWillMount() {
    this.loadCourses();
  }

  private async loadCourses() {
    this.setState({ showProgress: true });
    const courses = await this.CourseService.getAll();
    this.setState({ courses: courses.results, showProgress: false });
  }

  public render() {
    const { classes } = this.props;
    const { courses, showProgress } = this.state;

    const tempImage = 'https://www.cisl.cam.ac.uk/executive-education/learn-online/images/bespoke-online-learning.jpg';
    const { open } = this.state;

    const coursesDiv = courses.map((course: ICourse) => {
      const previewImage = course.hero_image ? course.hero_image : tempImage;
      const shortDesc = course.short_description ? course.short_description : course.description.substring(0, 128);

      return (
        <ListItem key={course.id}>
          <Link to={`/courses/${course.id}/preview`} style={{ width: '100%' }}>
            <Paper className={`${classes.root} course-box`} elevation={2}>
              <Grid container spacing={10} className={'course-info'}>
                <Grid item md={4} sm={12} style={{ padding: '0', width: '100%' }}>
                  <ProgressiveImage
                    preview={blurImage}
                    src={previewImage}
                    render={(src: any, style: any) => <img src={src} style={style} />}
                  />
                </Grid>
                <Grid item md={8} sm={12} className='couse-detail'>
                  <Typography variant='h4' color='inherit'>
                    {course.name}
                  </Typography>

                  <Typography variant='body1' color='inherit'>
                    {shortDesc}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </ListItem>
      );
    });

    return (
      <main className='Catalog'>
        {showProgress && <Progress />}
        <Header />

        <Grid container direction='row' justify='center' alignItems='center' spacing={10} className={'block-info'}>
          <Grid item md={10} sm={12}>
            <Typography variant='h4' color='inherit'>
              All Courses
            </Typography>

            <List component='nav' className={classes.root}>
              {!coursesDiv || coursesDiv.length === 0 && <Loader />}
              {coursesDiv && coursesDiv.length > 0 && coursesDiv}
            </List>
          </Grid>
        </Grid>

        <Footer />
      </main>
    );
  }
}

export default withStyles(styles)(Catalog);
