import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';
import { AddUnit, AddLesson } from '../../components/Modals';
import { ICourse, IUnit, IProfile, IProgress } from '../../interfaces';
import { AuthService, CourseService } from '../../services';
import { withAuth, Header, Progress, Footer } from '../../components';
import { withStyles } from '@material-ui/core/styles';
import Loader from './Loader';

import './Course.scss';
import { Grid, List, ListItem } from '@material-ui/core';

const styles = (theme: any) => ({
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 600,
    },
    root: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    continueBtn: {
        width: '200px',
    },
});
export interface Props {
    children?: React.ReactNode;
    classes: any;
    history: any;
    match: any;
    hideNav: any;
    passData: any;
    showLoader: any;
    hideLoader: any;
}

export interface State {
    course: ICourse;
    profile: IProfile;
    showProgress: boolean;
    progress?: IProgress;
    unit?: IUnit;
}

class Course extends React.Component<Props, State> {
    private Auth = new AuthService();
    private CourseService = new CourseService();
    constructor(props: Props) {
        super(props);
        this.setState({ showProgress: false });
    }

    public componentWillMount = () => {
        this.setState({ profile: this.Auth.getProfile() });
    }

    private resizeHeaderOnScroll = () => {
        const scrollTop: any = document.documentElement !== null ? document.documentElement.scrollTop : null;
        const distanceY = window.pageYOffset || scrollTop;
        const headEl = document.getElementsByClassName('head')[0];
        const slideBlock = document.getElementsByClassName('shrink-block')[0];
        if (distanceY > 350 && headEl && slideBlock) {
            headEl.classList.add('none');
            slideBlock.classList.remove('none');
        } else if (distanceY < 10 && headEl) {
            slideBlock.classList.add('none');
            headEl.classList.remove('none');
        }
    }

    public componentDidMount = async () => {
        this.setState({ showProgress: true });
        try {
            const { match: { params } } = this.props;
            const course = await this.CourseService.getOne(params.id);
            const progress = course.progress;
            let unit = null;

            console.log(course);
            if (progress) {
                unit = course.units[course.units.findIndex((obj: IUnit) => obj.id === progress.unit)];
                console.log(unit);
            }
            this.setState({ course, unit, progress });
            // this.props.passData(res);
        } catch (error) {
            throw new Error();
        }
        this.setState({ showProgress: false });
        window.addEventListener('scroll', this.resizeHeaderOnScroll);
    }

    public receiveAddedUnit = (unit: IUnit) => {
        const { course } = this.state;
        course.units.push(unit);

        this.setState({ course });
    }

    public receiveUpdatedUnit = (unit: IUnit) => {
        const { course } = this.state;
        const { units } = course;
        units[units.findIndex((obj: IUnit) => obj.id === unit.id)] = unit;
        course.units = units;
        this.setState({ course });
    }

    public render() {
        const { classes } = this.props;
        const { course, showProgress, unit, progress } = this.state;
        let unitDivs = null;
        let userProgress = 0;
        if (course && course.units) {
            unitDivs = course.units.map((u: IUnit) => {
                return (
                    <ListItem key={u.id}>
                        <ExpansionPanel defaultExpanded className='unit-item'>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}> UNIT {u.order} : {u.name}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className='details'>
                                <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={10}>
                                    <Grid item md={7} sm={12}>
                                        <Typography variant='body1' color='inherit'>
                                            {u.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                            <ExpansionPanelActions>
                                <Link className={classes.link} to={`/courses/${course.id}/units/${u.id}/lessons/${u.first_lesson}`} style={{ textDecoration: 'none' }}>
                                    <Button variant='outlined' color='primary' className={classes.button}>
                                        {unit && unit.id === u.id ? 'Ci gaba' : 'Fara Darasi'}
                                </Button>
                                </Link>
                            </ExpansionPanelActions>
                        </ExpansionPanel>
                    </ListItem>
                );
            });
        }

        if(course && progress) {
            userProgress = 100 / course.lesson_count * progress.completed_lessons.length;
        }

        return (
            <main className='Course'>
                {showProgress && <Progress />}

                {unitDivs &&
                <Grid container direction='row' justify='center' alignItems='center' spacing={10} className='head'>
                    <Grid item md={8} sm={12} className='course-title'>
                        <div>
                            <Link to='/'>
                                <div className='navigate'>
                                    <ChevronLeftIcon />
                                    <span>Koma baya</span>
                                </div>
                            </Link>
                            <Typography variant='h4' color='inherit'>
                                {course.name}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={12} className='course-prog'>
                        {unit && progress &&
                            <Link to={`/courses/${course.id}/units/${progress.unit}/lessons/${progress.lesson}`}>
                                <Button variant='contained' color='primary' className={classes.continueBtn}>Ci Gaba Unit {unit.order}</Button>
                            </Link>}
                    </Grid>

                    {unit && progress &&
                        <Grid item sm={12} xs={12} className='course-progress'>
                            <Typography variant='body1' color='inherit'>
                                Unit {unit.order} : {unit.name}
                            </Typography>
                            <Typography variant='body1' color='inherit' style={{ textAlign: 'right' }}>
                                {`${userProgress.toFixed(0)}% KAMMALAWA`}
                        </Typography>
                        </Grid>}
                </Grid>}

                {unitDivs &&
                <Grid container direction='row' justify='center' alignItems='center' spacing={10} className='shrink-block none'>
                    <Grid item md={12} sm={12} className='course-title'>
                        <Typography variant='h4' color='inherit'>
                            {course.name}
                        </Typography>

                        {unit && progress &&
                            <Link to={`/courses/${course.id}/units/${progress.unit}/lessons/${progress.lesson}`} className='course-prog'>
                                <Button variant='contained' color='primary' className={classes.continueBtn}>Ci Gaba Unit {unit.order}</Button>
                            </Link>}
                    </Grid>
                </Grid>}

                <Grid container direction='row' justify='center' alignItems='center' spacing={10} className={'courses'}>
                    <Grid item md={8} sm={12}>
                        <List component='nav' className={classes.root}>
                        {unitDivs ? unitDivs : <Loader/>}
                        </List>
                    </Grid>
                </Grid>

                <Footer />
            </main>
        );
    }
}

export default withAuth(withStyles(styles)(Course));
