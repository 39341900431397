import React from 'react';
import Typography from '@material-ui/core/Typography';
import ReactCrop from 'react-image-crop';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { AddCourse, UpdateProfile } from '../../components/Modals';
import { AuthService, CourseService, ProfileService } from '../../services';
import { withAuth, Progress, Header } from '../../components';
import { Link } from 'react-router-dom';
import { ICourse } from '../../interfaces';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import placeImageProfile from '../../assets/images/profile_pic_placeholder.png';
import Loader from './Loader';

import './Profile.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const styles: any = (theme: any) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing.unit,
        marginTop: theme.spacing.unit * 5,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    heading: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 4,
    },
    grid: {
        marginTop: theme.spacing.unit * 1.5,
    },
    cropBox: {
        display: 'block',
        maxHeight: theme.spacing.unit * 15,
    },
});

export interface Props {
    children?: React.ReactNode;
    classes: any;
    history: any;
    showLoader: any;
    hideLoader: any;
    hideNav: any;
}

class Profile extends React.Component<Props, any> {
    private Auth = new AuthService();
    private profileService = new ProfileService();
    constructor(props: Props) {
        super(props);
        this.state = {
            showProgress: false,
            profile: {},
            fileSelected: false,
            pixelCrop: null,
            open: false,
            disableCropBtn: false,
            crop: {
                width: 70,
                aspect: 1,
                className: 'cropClass',
            },
        };

        this.receiveProfile = this.receiveProfile.bind(this);
    }

    /**
     * @param {File} image - Image File Object
     * @param {Object} pixelCrop - pixelCrop Object from the 2nd argument of onChange or onComplete
     * @param {String} fileName - Name of the returned file in Promise
     */
    private getCroppedImg = (image: any, pixelCrop: any, fileName: string) => {

        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        if (!ctx) { return; }

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );

        // As Base64 string
        // const base64Image = canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob: any) => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg');
        });
    }

    public componentWillMount = async () => {
        this.setState({ showProgress: true });
        const profile = this.Auth.getProfile();
        const res = await this.profileService.getOne(profile.user_id);
        this.setState({ profile: res, showProgress: false });
    }

    public componentDidMount = () => {
        this.props.hideNav();
    }

    private selectPicture = (e: any) => {
        const elm = document.getElementById('avatar');
        if (elm) {
            elm.click();
        }
    }

    private cropPicture = (files: any) => {
        if (files && files.length > 0) {
            this.setState({ open: true, fileSelected: URL.createObjectURL(files[0]) });
        }

        const elm: any = document.getElementById('avatar');
        if (elm) {
            elm.value = '';
        }
    }

    private resizeCrop = (crop: any, pixelCrop: any) => {
        this.setState({ crop, pixelCrop });
    }

    private handleClose = () => {
        this.setState({ open: false });
    }

    private saveImage = async () => {
        this.setState({ showProgress: true, disableCropBtn: true });
        const pixelCrop = this.state.pixelCrop;
        const fileSelected = this.state.fileSelected;
        const returnedFileName = 'cropped_image.jpg';
        const image = new Image();
        image.src = fileSelected;

        if (image && pixelCrop && returnedFileName) {
            const croppedImg: any = await this.getCroppedImg(image, pixelCrop, returnedFileName);
            const currentAvatar: any = document.getElementById('currentAvatar');

            if (currentAvatar) {
                currentAvatar.src = URL.createObjectURL(croppedImg);
            }

            if (croppedImg) {
                const payload = new FormData();
                payload.append('headshot', croppedImg, 'image.jpg');
                try {
                    const res = await this.profileService.update(payload);
                    this.setState({ open: false });
                } catch (err) {
                    // error msg
                } finally {
                    this.setState({ showProgress: false, disableCropBtn: false });
                }
            }
        }
    }

    private receiveProfile = (profile: any) => {
        this.setState({ profile });
    }

    public render() {
        const { classes } = this.props;
        const { profile, showProgress, open, crop, fileSelected, disableCropBtn } = this.state;

        const { first_name, last_name, dob, email, gender, location, phone, tagline, username, progress } = profile;
        let avatar = null;
        if (profile && profile.headshot) {
            avatar = profile.headshot.avatar;
        }

        return (
            <div className='Profile'>
                <Header />
                {showProgress && <Progress />}

                {!profile.username &&
                    <Grid
                        container
                        direction='row'
                        justify='center'
                        spacing={10}>

                        <Grid item md={10} className='avatar-block'>
                            <Loader />
                        </Grid>
                    </Grid>}

                {profile.username &&
                <div className={classes.root}>

                    <Dialog
                        open={open}
                        onClose={this.handleClose}
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'>

                        <div>
                            {fileSelected && <ReactCrop
                                src={fileSelected}
                                crop={crop}
                                onChange={this.resizeCrop} />}

                            <Button disabled={disableCropBtn} onClick={this.saveImage} color='primary' fullWidth variant='contained'>
                                Save
                            </Button>
                        </div>
                    </Dialog>

                    <Grid
                        container
                        direction='row'
                        justify='center'
                        spacing={10}>

                        <Grid item xs={12} sm={3} className='avatar-block'>
                            <div className='avatar'>
                                <img src={avatar ? avatar : placeImageProfile} id='currentAvatar' />
                                <div className='middle'>
                                    <div className='text' onClick={this.selectPicture}>Change Picture</div>
                                </div>
                                <input name='avatar' id='avatar' type='file' className='hidden' onChange={(e) => this.cropPicture(e.target.files)} />
                            </div>

                            <div className='class-room'>
                                <Typography component='p' className={`${classes.heading} heading`}>Courses Enrolled</Typography>

                                {progress.map((p: any) => (
                                    <Grid key={p.id} container direction='row' justify='center' className={classes.grid}>
                                        <Grid item xs={12} sm={12}>
                                            <Link to={`/courses/${p.course.id}`}>
                                                <Typography component='h6' variant='h6'>{p.course.name}</Typography>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography color='primary' component='h6' variant='h6'>{100 / p.course.lesson_count * p.completed_lessons.length}% Completed</Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={5} className='info-block'>
                            <div className='top'>
                                <Typography component='h4' variant='h4'>{`${first_name} ${last_name}`}</Typography>

                                <Typography color='primary' component='h6' variant='h6'>{`${tagline}`}</Typography>

                                <div className='editBtn'>
                                    <UpdateProfile send={this.receiveProfile} profile={profile} />
                                </div>
                            </div>

                            <div>
                                <Typography component='p' className={`${classes.heading} heading`}>basic information</Typography>

                                <Grid container direction='row' justify='center' className={classes.grid}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography component='h6' variant='h6'>Gender:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Typography component='h6' variant='h6'>{gender === 'M' ? 'Male' : 'Female'}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container direction='row' justify='center' className={classes.grid}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography component='h6' variant='h6'>Birthday:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Typography component='h6' variant='h6'>{dob}</Typography>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className={`contact`}>
                                <Typography component='p' className={`${classes.heading} heading`}>contact information</Typography>

                                <Grid container direction='row' justify='center' className={classes.grid}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography component='h6' variant='h6'>Phone:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Typography color='primary' component='h6' variant='h6'>{phone}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container direction='row' justify='center' className={classes.grid}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography component='h6' variant='h6'>Address:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Typography component='h6' variant='h6'>{location}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container direction='row' justify='center' className={classes.grid}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography component='h6' variant='h6'>Email:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Typography color='primary' component='h6' variant='h6'>{email}</Typography>
                                    </Grid>
                                </Grid>

                            </div>
                        </Grid>

                    </Grid>
                </div>}
            </div>
        );
    }
}

export default withAuth(withStyles(styles)(Profile));
