import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import WifiIcon from '@material-ui/icons/Wifi';
import BluetoothIcon from '@material-ui/icons/Bluetooth';

import { UnitService, LessonService } from '../../services';
import { SnackBar, Sortable } from '../';
import { ICourse, IUnit, ILesson } from '../../interfaces';
import { Progress } from '../../components';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Paper, Divider, CardActions } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const styles: any = (theme: any) => ({
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 150,
    },
    button: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    paper: {
        height: 165,
        width: 170,
        padding: theme.spacing.unit * 2,
    },
    divider: {
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 1,
    },
    listContainer: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    item: {
        width: 'auto',
    },
});

function transition(props: any) {
    return <Slide direction='up' {...props} />;
}

export interface Props {
    children?: React.ReactNode;
    classes: any;
    course: ICourse;
    unit: IUnit;
    send?: any;
    lesson?: ILesson;
}

class AddLesson extends React.Component<Props, any> {
    private unitService = new UnitService();
    private lessonService = new LessonService();
    private baseState: any;
    constructor(props: any) {
        super(props);
        const { course, lesson } = this.props;
        // const { unit_object } = lesson;
        this.state = {
            course,
            add_answer: '',
            name: '',
            hint: '',
            content_type: '',
            open: false,
            showLessons: lesson ? false : true,
            disableButton: false,
            openSnackBar: false,
            showProgress: false,
            updateLesson: false,
            answers: {},
            editorState: EditorState.createEmpty(),
        };
    }

    public async componentWillMount() {
        this.showProgress();
        const { unit } = this.props;
        const res = await this.unitService.getOne(unit.id);
        this.setState({ unit: res });
        this.hideProgress();
    }

    public componentDidMount() {
        this.setEditorState('');
    }

    private setEditorState = (html: string) => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({ editorState });
        }
    }

    private onEditorStateChange = (editorState: any) => {
        this.setState({ editorState });
    }

    public handleOpen = () => {
        this.setState({ open: true });
    }

    public showProgress = () => {
        this.setState({ showProgress: true });
    }

    public hideProgress = () => {
        this.setState({ showProgress: false });
    }

    public handleClose = () => {
        this.restoreState();
        this.setState({ open: false });
    }

    private restoreState = () => {
        const baseState: any = {};
        for (const key in this.state) {
            if (key in this.state) {
                baseState[key] = null;
            }
        }

        baseState['unit'] = this.state.unit;
        baseState['course'] = this.state.course;
        baseState['open'] = true;
        this.setState(baseState);
    }

    public showLessonForm = () => {
        this.setState({ showLessons: false });
    }

    public hideLessonForm = () => {
        this.restoreState();
        this.setState({ open: true, showLessons: true });
    }

    private handleChange = (e: any, name: string) => {
        this.setState({ [name]: e.target.value });
        if (name === 'content_type') {
            this.setState({ answers: {} });
        }

        if (name === 'question_type') {
            if (e.target.value === 'TF') {
                this.setState({ answers: { 'answer_true': { correct: true }, 'answer_false': { correct: false } } });
            } else {
                this.setState({ answers: {} });
            }
        }
    }

    private addAnswer = () => {
        const { add_answer, answers } = this.state;
        if (add_answer && add_answer.length > 0) {
            answers[add_answer] = { content: add_answer, correct: false };
            this.setState({ answers, add_answer: '' });
        }
    }

    private handleAnswers = (e: any, name: string, question_type: string = '') => {
        const { answers } = this.state;

        if (question_type === 'TF' || question_type === 'SC') {
            for (const key in answers) {
                if (key !== name) {
                    answers[key].correct = false;
                }
            }
        }
        answers[name].correct = !answers[name].correct;
        if (question_type === 'TF') {
            answers[name === 'answer_true' ? 'answer_false' : 'answer_true'].correct = false;
        }
        this.setState({ answers });
        console.log(this.state.answers);
    }

    private getHTML = () => {
        const { lecture_type, editorState } = this.state;
        if (lecture_type === 'HTML') {
            const rawContentState = convertToRaw(editorState.getCurrentContent());
            return draftToHtml(rawContentState);
        }
        return false;
    }

    private setEditLesson = (lesson: any) => {
        const { unit } = this.state;
        const { name, course, id, order, content_type, description, unit_object } = lesson;

        const tempState: any = { name, unit, course, order, content_type, description, lessonId: id };

        if (content_type === 'lecture') {
            const { html, video, picture, lecture_type } = unit_object;
            tempState['lecture_type'] = lecture_type;
            tempState['html'] = html;
            tempState['video'] = video;
            tempState['picture'] = picture;
            tempState['object_id'] = unit_object.id;
            if (html) {
                this.setEditorState(html);
            }
        } else if (content_type === 'question') {
            const { answers, content, hint, question_type } = unit_object;
            tempState['question_type'] = question_type;
            tempState['content'] = content;
            tempState['hint'] = hint;
            tempState['object_id'] = unit_object.id;
            tempState['answers'] = answers;
        } else {
            return;
        }

        tempState['showLessons'] = false;
        tempState['updateLesson'] = true;
        this.setState(tempState);
    }

    public addLesson = (event: any) => {
        event.preventDefault();

        const { answers, unit, updateLesson, content_type, object_id, lessonId, lecture_type, question_type, vid, content, name, order, description, hint } = this.state;
        const { course } = this.props;
        const html = this.getHTML();
        const unitId: number = unit.id;

        if (lecture_type === 'HTML' && html.length < 10) {
            this.setState({ errorMessage: 'provide a valid text', openSnackBar: true });
            return;
        }

        if (content_type === 'question' && question_type !== 'BQ' && Object.keys(answers).length < 2) {
            this.setState({ errorMessage: 'provide answers for a question', openSnackBar: true });
            return;
        }

        const ans = [];
        if (content_type === 'question' && question_type !== 'BQ') {
            let valid = true;
            for (const key in answers) {
                if (answers[key].correct === true) {
                    valid = true;
                }
                ans.push(answers[key]);
            }

            if (!valid) {
                this.setState({ errorMessage: 'provide valid answers for a question', openSnackBar: true });
                return;
            }
        }

        if (content_type !== 'question' && content_type !== 'lecture') {
            this.setState({ errorMessage: 'provide a valid information', openSnackBar: true });
            return;
        }

        const payload: any = {
            name,
            description,
            order,
            question_type,
            hint,
            vid, html,
            content_type,
            content,
            answers: ans,
            unit: unitId,
            unit_object: {
                content,
                content_type,
                question_type,
                html,
                vid,
                answers: ans,
            },
        };

        if (content_type === 'question' && question_type !== 'BQ') {
            payload['answers_size'] = Object.keys(answers).length;
        }

        this.setState({
            disableButton: true,
            openSnackBar: false,
            showProgress: true,
        });

        if (updateLesson && lessonId) {
            payload['id'] = lessonId;
            payload['object_id'] = object_id;
            payload['unit_object']['id'] = object_id;
            this.lessonService
                .update(payload)
                .then(res => {
                    this.setState({ errorMessage: 'Lesson Udated', showProgress: false, disableButton: false, openSnackBar: true, showLessons: true });
                    setTimeout(() => {
                        this.hideLessonForm();
                    }, 1500);
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ errorMessage: 'Cannot update lesson', showProgress: false, openSnackBar: true, disableButton: false });
                });
        } else {
            payload['course'] = course.id;
            this.lessonService
                .add(payload)
                .then((res: any) => {
                    unit.lessons.push(res);
                    this.setState({ unit, errorMessage: 'Lesson added', showProgress: false, disableButton: false, openSnackBar: true, showLessons: true });
                    console.log(this.state);
                    setTimeout(() => {
                        this.hideLessonForm();
                    }, 1500);
                })
                .catch((err: any) => {
                    console.log(err);
                    this.setState({ errorMessage: 'Cannot add lesson', showProgress: false, openSnackBar: true, disableButton: false });
                });
        }
    }

    public render() {
        const { disableButton,
            openSnackBar,
            showProgress,
            showLessons,
            unit,
            lecture_type,
            question_type,
            name,
            order,
            content_type,
            description,
            editorState,
            content,
            answers,
            lesson,
            errorMessage } = this.state;

        if (!unit) { return null; }

        const { classes, course } = this.props;

        console.log(answers);

        const options = [];
        const end = unit.lessons.length + 1;
        for (let i = 1; i <= end; i += 1) {
            options.push(<option key={i} value={i}>{i}</option>);
        }

        const lessons = unit.lessons.map((lesson: ILesson, index: number) => {
            return (<ListItem className={classes.item}>
                <Grid key={lesson.id} item>
                    <Paper className={classes.paper}>
                        <Typography variant='h5' component='h3'>
                            {lesson.name}
                        </Typography>
                        <Divider className={classes.divider} />
                        <Typography component='p'>
                            {lesson.description}
                        </Typography>
                        <CardActions>
                            <Link to={`/courses/${lesson.course}/units/${lesson.unit}/lessons/${lesson.id}`}>
                                <Button size='small' color='primary'>
                                    View
                                </Button>
                            </Link>
                            <Button size='small' variant='contained' color='primary' onClick={() => { this.setEditLesson(lesson); }}>
                                Edit
                            </Button>
                        </CardActions>
                    </Paper>
                </Grid>
            </ListItem>);
        });

        return (
            <div className='AddLesson'>
                <Button onClick={this.handleOpen} variant='outlined' color='secondary' className={classes.button}>
                    Lessons
                </Button>

                <SnackBar open={openSnackBar} message={errorMessage} />

                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={transition}
                >
                    {showProgress && <Progress />}

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color='inherit' onClick={this.handleClose} aria-label='Close'>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant='h6' color='inherit' className={classes.flex}>
                                {course.name}:{unit.name}
                            </Typography>
                            {showLessons && <Button variant='contained' onClick={this.showLessonForm}>
                                <AddIcon /> Lesson
                            </Button>}
                            {!showLessons && <Button variant='contained' onClick={this.hideLessonForm}>
                                Close
                            </Button>}
                            <Button disabled={disableButton} color='inherit' type='submit'>
                                Update Unit
                            </Button>
                        </Toolbar>
                    </AppBar>

                    {/* {showLessons && <Sortable unit={unit} course={course} lessons={unit.lessons} editLesson={this.setEditLesson} />} */}

                    {showLessons &&
                        <List className={classes.listContainer}>
                            {lessons}
                        </List>
                    }

                    {!showLessons && <form className={classes.container} onSubmit={this.addLesson}>
                        <List>
                            <ListItem>
                                <TextField
                                    required
                                    label='Lesson Title'
                                    className={classes.textField}
                                    name='name'
                                    value={this.state.name}
                                    onChange={(e) => this.handleChange(e, 'name')}
                                    autoComplete='name'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    required
                                    label='Unit Description'
                                    name='description'
                                    value={this.state.description}
                                    autoComplete='description'
                                    onChange={(e) => this.handleChange(e, 'description')}
                                    multiline
                                    rows='2'
                                    className={classes.textField}
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <FormControl variant='filled' className={`${classes.formControl} ${classes.textField}`}>
                                    <InputLabel htmlFor='filled-age-native-simple'>Order</InputLabel>
                                    <Select
                                        required
                                        native
                                        fullWidth
                                        value={this.state.order}
                                        onChange={(e) => this.handleChange(e, 'order')}
                                        input={<FilledInput name='order' id='filled-age-native-simple' />}
                                    >
                                        <option value='' />
                                        {options}
                                    </Select>
                                </FormControl>
                                <FormControl variant='filled' className={`${classes.formControl} ${classes.textField}`}>
                                    <InputLabel htmlFor='filled-age-native-simple'>Type</InputLabel>
                                    <Select
                                        required
                                        native
                                        fullWidth
                                        value={this.state.content_type}
                                        onChange={(e) => this.handleChange(e, 'content_type')}
                                        input={<FilledInput name='type' id='filled-age-native-simple' />}
                                    >
                                        <option value='' />
                                        <option value='lecture'>Lecture</option>
                                        <option value='question'>Question</option>
                                    </Select>
                                </FormControl>

                                {content_type === 'lecture' &&
                                    <FormControl variant='filled' className={`${classes.formControl} ${classes.textField}`}>
                                        <InputLabel htmlFor='filled-age-native-simple'>Lecture Type</InputLabel>
                                        <Select
                                            required
                                            native
                                            fullWidth
                                            value={this.state.lecture_type}
                                            onChange={(e) => this.handleChange(e, 'lecture_type')}
                                            input={<FilledInput name='lecture_type' id='filled-age-native-simple' />}
                                        >
                                            <option value='' />
                                            <option value='HTML'>Text</option>
                                            <option value='VID'>Video</option>
                                        </Select>
                                    </FormControl>}

                                {content_type === 'question' &&
                                    <FormControl variant='filled' className={`${classes.formControl} ${classes.textField}`}>
                                        <InputLabel htmlFor='filled-age-native-simple'>Question Type</InputLabel>
                                        <Select
                                            required
                                            native
                                            fullWidth
                                            value={this.state.question_type}
                                            onChange={(e) => this.handleChange(e, 'question_type')}
                                            input={<FilledInput name='question_type' id='filled-age-native-simple' />}
                                        >
                                            <option value='' />
                                            <option value='BQ'>Input Answer</option>
                                            <option value='SC'>Single Choice</option>
                                            <option value='MC'>Multi Choice</option>
                                            <option value='TF'>True Or False</option>
                                        </Select>
                                    </FormControl>}
                            </ListItem>
                            {content_type === 'lecture' && lecture_type === 'HTML' &&
                                <ListItem className={classes.textField}>
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName='demo-wrapper'
                                        editorClassName='demo-editor'
                                        localization={{
                                            locale: 'ha',
                                        }}
                                        onEditorStateChange={this.onEditorStateChange}
                                    />
                                    {/* <CKEditor
                                        editor={ClassicEditor}
                                        data="<p>Hello from CKEditor 5!</p>"
                                        onInit={(editor: any) => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event: any, editor: any) => {
                                            const data = editor.getData();
                                            console.log({ event, editor, data });
                                        }}
                                        onBlur={(editor: any) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(editor: any) => {
                                            console.log('Focus.', editor);
                                        }}
                                    /> */}
                                </ListItem>}

                            {content_type === 'lecture' && lecture_type === 'VID' &&
                                <ListItem>
                                    <TextField
                                        required
                                        label='Video URL'
                                        className={classes.textField}
                                        name='vid'
                                        value={this.state.vid}
                                        onChange={(e) => this.handleChange(e, 'vid')}
                                        autoComplete='video'
                                        margin='normal'
                                        variant='filled'
                                        fullWidth
                                    />
                                </ListItem>}

                            {content_type === 'question' && question_type &&
                                <ListItem>
                                    <TextField
                                        required
                                        label='Question'
                                        name='content'
                                        value={this.state.content}
                                        autoComplete='content'
                                        onChange={(e) => this.handleChange(e, 'content')}
                                        multiline
                                        rows='2'
                                        className={classes.textField}
                                        margin='normal'
                                        variant='filled'
                                        fullWidth
                                    />
                                </ListItem>}

                            <Grid container className={classes.root} spacing={10}>
                                <Grid item xs={5}>
                                    {content_type === 'question' && question_type === 'TF' &&
                                        <List subheader={<ListSubheader>Answers <span style={{ float: 'right' }}>Correct?</span> </ListSubheader>} className={classes.textField}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <WifiIcon />
                                                </ListItemIcon>
                                                <ListItemText primary='True' />
                                                <ListItemSecondaryAction>
                                                    <Switch
                                                        onChange={(e) => this.handleAnswers(e, 'answer_true', 'TF')}
                                                        checked={answers['answer_true'].correct ? answers['answer_true'].correct : false}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <BluetoothIcon />
                                                </ListItemIcon>
                                                <ListItemText primary='False' />
                                                <ListItemSecondaryAction>
                                                    <Switch
                                                        onChange={(e) => this.handleAnswers(e, 'answer_false', 'TF')}
                                                        checked={answers['answer_false'].correct ? answers['answer_false'].correct : false}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>}

                                    {content_type === 'question' && question_type && question_type !== 'TF' && question_type !== 'BQ' && Object.keys(answers).length > 0 &&
                                        <List subheader={<ListSubheader>Answers <span style={{ float: 'right' }}>Correct?</span> </ListSubheader>} className={classes.textField}>
                                            {Object.keys(answers).map((key, index) => (
                                                <ListItem key={index}>
                                                    <ListItemIcon>
                                                        <WifiIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={key} />
                                                    <ListItemSecondaryAction>
                                                        <Switch
                                                            onChange={(e) => this.handleAnswers(e, key, question_type)}
                                                            checked={answers[key].correct ? answers[key].correct : false}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))}
                                        </List>}

                                    {content_type === 'question' && question_type && question_type !== 'TF' && question_type !== 'BQ' &&
                                        <List>
                                            <ListItem>
                                                <TextField
                                                    label='Add Answer Option'
                                                    name='add_answer'
                                                    value={this.state.add_answer}
                                                    autoComplete='option'
                                                    className={classes.textField}
                                                    style={{ minWidth: '70%' }}
                                                    onChange={(e) => this.handleChange(e, 'add_answer')}
                                                    margin='normal'
                                                    variant='filled' />

                                                <Button variant='contained' color='primary' onClick={this.addAnswer}>
                                                    <AddIcon /> Answer
                                            </Button>
                                            </ListItem>
                                        </List>}
                                </Grid>
                            </Grid>

                            {content_type === 'question' && question_type &&
                                <ListItem>
                                    <TextField
                                        required
                                        label='Answer Hint'
                                        name='hint'
                                        value={this.state.hint}
                                        autoComplete='hint'
                                        onChange={(e) => this.handleChange(e, 'hint')}
                                        multiline
                                        rows='2'
                                        className={classes.textField}
                                        margin='normal'
                                        variant='filled'
                                        fullWidth
                                    />
                                </ListItem>}

                            {content_type && (question_type || lecture_type) && name && order && description && <ListItem>
                                <Button type='submit' disabled={disableButton} className={classes.textField} variant='contained' color='primary' size='large'>
                                    Save Lesson
                                </Button>
                            </ListItem>}
                        </List>
                    </form>}
                </Dialog>
            </div >
        );
    }
}

export default withStyles(styles)(AddLesson);
