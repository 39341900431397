import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import ImportContacts from '@material-ui/icons/ImportContacts';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ICourse, IProfile } from '../../interfaces';
import { AddCourse } from '../../components/Modals';
import { AuthService, CourseService } from '../../services';
import { withAuth, Header, Footer, Progress } from '../../components';
import { List, ListItem, Paper } from '@material-ui/core';
import Loader from './Loader';
import blurImage from '../../assets/images/blur.jpg';
import ProgressiveImage from 'react-progressive-image-loading';
import './Home.scss';

const styles: any = (theme: any) => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    continueBtn: {
        width: '160px',
        marginTop: '40px',
    },
});

export interface Props {
    children?: React.ReactNode;
    classes: any;
    history: any;
    showLoader: any;
    hideLoader: any;
}

export interface State {
    courses: ICourse[];
    profile: IProfile;
    showProgress: boolean;
}

class Home extends React.Component<Props, State> {
    private Auth = new AuthService();
    private CourseService = new CourseService();
    constructor(props: Props) {
        super(props);
        this.state = {
            profile: this.Auth.getProfile(),
            courses: [],
            showProgress: false,
        };
    }

    public async componentDidMount() {
        this.props.showLoader();
        const res = await this.CourseService.getAll();
        this.setState({ courses: res.results });
        this.props.hideLoader();
    }

    public receiveEditedCourse = (course: ICourse) => {
        const { courses } = this.state;
        courses[courses.findIndex((obj) => obj.id === course.id)] = course;
        this.setState({ courses });
    }

    public render() {
        const { classes } = this.props;
        const { profile, courses, showProgress } = this.state;
        const tempImage = 'https://www.cisl.cam.ac.uk/executive-education/learn-online/images/bespoke-online-learning.jpg';

        let coursesDiv = null;
        let similarDivs = null;
        const similarCourses: any = [];
        if (courses) {
            coursesDiv = courses.map((course: ICourse) => {
                const previewImage = course.hero_image ? course.hero_image : tempImage;
                const progress = course.progress;
                if (!progress) {
                    similarCourses.push(course);
                    return;
                }
                const link = `/courses/${course.id}/units/${progress.unit}/lessons/${progress.lesson}`;
                const progValue = 100 / course.lesson_count * progress.completed_lessons.length;

                return (
                    <ListItem key={course.id}>
                        <Paper className='course-box' elevation={2}>
                            <Grid container spacing={10} className='course-info'>
                                <Grid item md={4} sm={12} style={{ padding: '0', height: '100%' }}>
                                    <ProgressiveImage
                                        preview={blurImage}
                                        src={previewImage}
                                        render={(src: any, style: any) => <img src={src} style={style} />}
                                    />
                                </Grid>
                                <Grid item md={8} sm={12} className='couse-detail'>
                                    <Link to={`/courses/${course.id}`}>
                                    <Typography variant='h4' color='inherit'>
                                        {course.name}
                                    </Typography>
                                    </Link>

                                    <LinearProgress variant='buffer' value={progValue} valueBuffer={0} />

                                    <Link to={link}><Button variant='contained' color='primary' className={classes.continueBtn}>Ci Gaba</Button></Link>
                                </Grid>
                            </Grid>
                        </Paper>
                    </ListItem>
                );
            });

            similarDivs = similarCourses.map((c: ICourse) => {
                const previewImage = c.hero_image ? c.hero_image : tempImage;

                return (
                    <Grid item key={c.id} sm={6} md={4} lg={3}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={previewImage}
                                title={c.name}
                            />
                            <CardContent className={classes.cardContent}>
                                <Link to={`/courses/${c.id}/preview`}>
                                    <Typography gutterBottom variant='body1'>
                                        {c.name.toLowerCase()}
                                    </Typography>
                                </Link>
                            </CardContent>
                        </Card>

                    </Grid>);
            });
        }

        return (
            <main className='Home'>
                {showProgress && <Progress />}
                <Header />

                <Grid container direction='row' justify='center' alignItems='center' spacing={10} className={'block-info'}>
                    <Grid item md={10} sm={12}>
                        <Typography variant='h4' color='inherit'>
                            Darussan da kake dauka
                        </Typography>

                        <List component='nav' className={classes.root}>
                            {!coursesDiv || coursesDiv.length === 0 && <Loader />}
                            {coursesDiv && coursesDiv.length > 0 && coursesDiv}
                        </List>
                    </Grid>
                </Grid>

                {similarDivs && similarDivs.length > 0 &&
                    <Grid container direction='row' justify='center' alignItems='center' spacing={10} className={'similar-info'}>
                        <Grid item md={10} sm={12}>
                            <Typography variant='h4' color='inherit'>
                                Darussan da ya kamata ka duba
                        </Typography>

                            <List component='nav' className={classes.root}>
                                {similarDivs}
                            </List>
                        </Grid>
                    </Grid>}

                <Footer />
            </main>
        );
    }
}

export default withAuth(withStyles(styles)(Home));
